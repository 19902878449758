import React from "react";
import { Link } from "react-router-dom";

import ContactIcons from "../Contact/ContactIcons";

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <div className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </div>
      <header>
        <h2>Usmonov Azizbek</h2>
        <p>
          <a href="mailto:usmonovazizbek15@gmail.com">
            usmonovazizbek15@gmail.com
          </a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>Ma'lumot</h2>
      <p>
        I am Usmanov Azizbek. I'm a <b>Frontend</b>, <b>Mobile</b> developer. I
        also know about <b>UI/UX</b> (I also worked in Real Projects).
      </p>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; Usmonov Azizbek <Link to="/">azizziy.uz</Link>.
      </p>
    </section>
  </section>
);

export default SideBar;
