import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faGitlab } from "@fortawesome/free-brands-svg-icons/faGitlab";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons/faLinkedinIn";
import { faTelegram } from "@fortawesome/free-brands-svg-icons/faTelegram";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons/faEnvelope";
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: "https://github.com/usmonovdev",
    label: "Github",
    icon: faGithub,
  },
  {
    link: "https://gitlab.com/azizziy",
    label: "Gitlab",
    icon: faGitlab,
  },
  {
    link: "https://www.linkedin.com/in/usmonov-azizbek/",
    label: "LinkedIn",
    icon: faLinkedinIn,
  },
  {
    link: "https://t.me/usmonov_ku",
    label: "Telegram",
    icon: faTelegram,
  },
  {
    link: "mailto:usmonovazizbek15@gmail.com",
    label: "Email",
    icon: faEnvelope,
  },
];

export default data;
